exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-application-index-jsx": () => import("./../../../src/pages/application/index.jsx" /* webpackChunkName: "component---src-pages-application-index-jsx" */),
  "component---src-pages-company-index-jsx": () => import("./../../../src/pages/company/index.jsx" /* webpackChunkName: "component---src-pages-company-index-jsx" */),
  "component---src-pages-en-terms-of-services-index-jsx": () => import("./../../../src/pages/en/terms-of-services/index.jsx" /* webpackChunkName: "component---src-pages-en-terms-of-services-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-index-jsx": () => import("./../../../src/pages/legal/index.jsx" /* webpackChunkName: "component---src-pages-legal-index-jsx" */),
  "component---src-pages-store-redirection-index-tsx": () => import("./../../../src/pages/store-redirection/index.tsx" /* webpackChunkName: "component---src-pages-store-redirection-index-tsx" */),
  "component---src-pages-terms-of-services-index-jsx": () => import("./../../../src/pages/terms-of-services/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-services-index-jsx" */)
}

